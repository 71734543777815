
export const OTHERS = 'Others';
export const NPK = 'NPK';

export const OPEN_PRODUCT = 'Open Products';
export const SUBSIDY_PRODUCT = 'Subsidized Products Imported';
export const STRAIGHT_PRODUCT = 'Subsidized Import';
export const BLEND_PRODUCT = 'Subsidized Domestic Blends';

export const FUBC_FIELD = 'applicationRate';
export const FUBC_COUNTY = 'county';

export const KENYA_COUNTRY_GEONAME_ID = 192950; //TODO remove this hardcode
export const KENYA_CENTER_POINT = {y: -0.28667330341043323, x: 40.22094726562501};

//TODO: calculations must be moved to the backend for the following:
export const UNIT_KG_PER_ACRE = 'KG_PER_ACRE';
export const FUBC_TO_ACRE_CONVERSION = 0.4047;
export const HECTARE_TO_ACRE = 2.47105;

export const DATES_TYPES = {
	MONTHS: 'months',
	YEARS: 'years',
	PERIODS: 'periods'
}

export const PRESENTATION_TYPES = {
	LINE_CHART: 'lineChart',
	MAP: 'map',
	INFO_TABLE: 'infoTable',
	PIE_CHART: 'pieChart',
	BAR_CHART: 'barChart',
	TABLE: 'table'
};

export const APPARENT_CONSUMPTION = "Apparent Consumption";

export const NUTRIENTS = [{ id: "Nitrogen (N)", name: "Nitrogen (N)", code: "NITROGEN" },
{ id: "Phosphorus (P2O5)", name: "Phosphorus (P2O5)", code: "PHOSPHOROUS" },
{ id: "Potassium (K2O)", name: "Potassium (K2O)", code: "POTASSIUM" }];

export const CBU_CATEGORIES = [
	{id: 'CIF', name: 'CIF', langKey: 'price:cif'},
	{id: 'Clearing', name: 'Clearing', langKey: 'price:clearing'},
	{id: 'Bagging', name: 'Bagging', langKey: 'price:bagging'},
	{id: 'Warehousing', name: 'Warehousing', langKey: 'price:warehousing'},
	{id: 'Finance', name: 'Finance', langKey: 'price:finance'},
	{id: 'Road Freight', name: 'Road Freight', langKey: 'price:roadFreight'}
]

export const COLORS_2 = ['#5e150c', '#2b9ff6', '#629328', '#911a0c',
    '#8fc44f', '#bfec8a', '#ebec8a', '#f9c64d', '#e3a512', '#aa7e14', '#b46258', '#c04232', '#3375b8']


export const COLORS_1 = ['#E31900', '#BFEC8A', '#3374B7', '#F7988B', '#DF9300', '#629228',
    '#F7B811', '#F7D822', '#EBEC8A', '#F56551', '#8FC44F', '#90CCF9', '#419FF6']


export const COLORS = ['#629228', '#F7B811', '#C17AC2', '#FE4D4D', '#FFD822',
    '#F78A7C', '#90CCF9', '#2B9FF6', '#3375B8', '#FFB5AC', '#EBEC8A', '#C0C0C0',
    '#800000', '#008080', '#FF00FF'];

export const COLORS_area = ['#6d913a', '#ec5b55', '#4b9ced', '#efb642', '#FFD822',
    '#F78A7C', '#90CCF9', '#2B9FF6', '#3375B8', '#FFB5AC', '#EBEC8A', '#C0C0C0',
    '#800000', '#008080', '#FF00FF'];

export const COLORS_CROPLAND_MAP = ['#9e0b0f', '#dd4b11', '#ff8f45', '#ffbd76', '#ffd782', '#fdf099'];

export const CBU_CATEGORY_COLORS = {
	      'CIF': "#06529F",
	      'Clearing': "#43740A",
	      'Bagging': "#C58A01",
	      'Warehousing': "#C00909",
	      'Finance': "#840086",
	      'Road Freight': "#90ccf9"
	    };

export const TOP_CROPS_COLOR_MAPPING = {
  'Tea': '#8fc44f',
  'Beans': '#be0f17',
  'Maize': '#feb730',
  'Flowers': '#90ccf9',
  'Wheat': '#df9c24',
  'Irish Potatoes': '#ae7b1c',
  'Sugarcane': '#629328',
  'Other Food Crops': '#949494',
  'Other Cash Crops': '#bdbdbd',
}

export const VIFAA_COLORS = [
	'#06529F',
	'#43740A',
	'#C58A01',
	'#C00909',
	'#840086',
	'#3375B8',
	'#629328',
	'#FFB811',
	'#FE4D4D',
	'#D35400',
	'#2B9FF6',
	'#8FC44F',
	'#FFD822',
	'#FF8A7C',
	'#C17AC2',
	'#90CCF9',
	'#BFEC8A',
	'#EBED21',
	'#FFB5AC',
	'#FDFF56',
  '#40f290',
];

export const PRODUCT_BASAL = 'Basal';
export const PRODUCT_TOP_DRESSING = 'Top dressing';

export const LAND_AREA_OPTIONS_QED = [
	{ id: "byCroplandAreaQED", name: "Cropland Satellite Analysis (QED)", code: "CROPLAND_SI_QED", langKey: 'use:croplandSatelliteAnalysisQed' },
	{ id: "byCroplandArea", name: "Cropland (FAO)", code: "CROPLAND", langKey: 'use:croplandFao' }
];

export const COMMERCIAL_AVG_PRICE = 'Commercial Average';
export const SUBSIDIZED_AVG_PRICE = 'Subsidy Average';

export const THREE_MONTHS_PRICE_COMP_STATIC_COLORS = { [COMMERCIAL_AVG_PRICE]: VIFAA_COLORS[0], [SUBSIDIZED_AVG_PRICE]: VIFAA_COLORS[1]};

export const VIFAA_COUNTRIES = {
	GHANA: 'GH',
	NIGERIA: 'NG',
	KENYA: 'KE',
	SENEGAL: 'SN',
	ZAMBIA: 'ZM',
	MALAWI: 'MW',
	ETHIOPIA: 'ET',
	MOZAMBIQUE: 'MZ'
}

export const COUNTRY_LINKS = {
	GH: 'ghana',
	NG: 'nigeria',
	KE: 'kenya',
	SN: 'senegal',
	ZM: 'zambia',
	MW: 'malawi',
	ET: 'ethiopia',
	MZ: 'mozambique'
}

export const COUNTRY_CENTROIDS = {
	GH: { longitude: -1.21677, latitude: 7.953456 },
	NG: { longitude: 8.08943895, latitude: 9.59411452 },
	KE: { longitude: 37.9155074, latitude: 0.6314842},
	SN: { longitude: -15.6380957, latitude: 14.476273},
	ZM: { longitude: 27.8541907, latitude: -13.1403509},
	MW: { longitude: 34.301525, latitude: -13.254308},
	ET: { longitude: 40.489673, latitude: 9.145},
	MZ: { longitude: 35.90, latitude: -18.66}
}

export const MONTHLY_VIEW = 'monthly';
export const YEARLY_VIEW = 'yearly';
export const MONTHS_VIEW = 'months';

export const ABUJA_TARGET = 50;
export const CROP_LAND_FAO = 'Cropland (FAO)';
export const CROP_LAND_QED = 'Cropland Satellite Analysis (QED)';

export const BY_NUTRIENT = 'nutrientTon';
export const BY_PRODUCT = 'productTon';

export const CROP_TYPES = [
	{id: 'all', name: 'All Crops', langKey: 'use:allCrops'},
	{id: 'food', name: 'Food Crops', langKey: 'use:foodCrops'},
	{id: 'cash', name: 'Cash Crops', langKey: 'use:cashCrops'}
]
